<template>
  <div>
    <header class="index-header">
      <div class="fixed-top header-main">
        <!-- <nav class="navbar navbar-light bg-white"> -->
        <nav class="bg-white"
          :class="this.$auth.setting && this.$auth.setting.active_restaurant > '1' ? 'navbar navbar-light' : ''">
          <div class="d-flex align-items-center px-sm-3">
            <div class="delivery-pickup-main d-flex align-items-center">
              <div class="delivery-pickup-lnk"
                v-if="this.$route.name == 'home' && $auth.setting && $auth.setting.is_pickup_enable == 'true'">
                <div class="switch" id="switch">
                  <input name="radio" @click="getDelivery('1')" type="radio" value="1" id="delivery"
                    :checked="isPickupDelivery == 1">
                  <label for="delivery">{{ $t('Delivery') }}</label>
                  <input name="radio" @click="getDelivery('0')" type="radio" value="0" id="pickup"
                    :checked="isPickupDelivery == 0">
                  <label for="pickup" class="right">{{ $auth.setting && $auth.setting.terminology &&
                    $auth.setting.terminology[0].takeaway_text }}</label>
                  <!-- <label for="pickup" class="right">{{ $t('Takeaway') }}</label> -->
                  <span class="swtact" :class="this.rightClass || { right: ['0'].includes(isPickupDelivery) }"
                    aria-hidden="true"></span>
                </div>
              </div>



              <!-- <div>
                <a href="javascript:void(0)" @click="isStore = true" class="delivery-link map-location set-address-link"
                  v-if="this.$route.name == 'home' && is_scrren_hide_mobile && isPickupDelivery == '1'">
                  <span class="delivery-image">
                    <img src="@/assets/images/shop-icon.png" alt="offer" height="25" width="25">
                  </span>
                  <span v-if="address == ''">{{ $t('Select store') }}</span>
                  <p v-else class="text-truncate d-inline-block d-flex flex-column" v-bind:title="address">
                    <span class="outl-nme">{{ $t('Select') }} {{ $auth.setting && $auth.setting.restaurant_name
                      }}</span>
                    <span class="outl-addrs">{{ singleRestaurant.name }}</span>
                  </p>
                </a>
              </div> -->

              <div v-if="this.$auth.setting && this.$auth.setting.active_restaurant > '1'">
                <a href="javascript:void(0)" @click="isStore = true" class="delivery-link map-location set-address-link"
                  v-if="this.$route.name == 'home' && isPickupDelivery == '1' && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0">
                  <span class="delivery-image">
                    <img src="@/assets/images/shop-icon.png" alt="offer" height="25" width="25">
                  </span>
                  <span v-if="address == ''">{{ $t('Select store') }}</span>
                  <p v-else class="text-truncate d-inline-block d-flex flex-column" v-bind:title="address">
                    <span class="outl-nme">{{ $t('Select') }} {{ $auth.setting && $auth.setting.restaurant_name
                      }}</span>
                    <span class="outl-addrs">{{ singleRestaurant.name }}</span>
                  </p>
                </a>
              </div>

              <!-- :class="{ 'delivery-pickup-main': true, 'no-justify': $auth.setting.is_pickup_enable === 'false' }" -->
              <!-- class="delivery-link map-location set-address-link" -->
              <a href="javascript:void(0)" @click="deliveryModalAddress"
                class="delivery-link map-location set-address-link"
                v-if="this.$route.name == 'home' && isPickupDelivery == '1' && this.$auth.setting.product_type.id != 2">
                <span class="delivery-image">
                  <svg width="20" height="25" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100"
                    enable-background="new 0 0 100 100" xml:space="preserve">
                    <g>
                      <path fill="#000000"
                        d="M-293.039-548.582c-3.294,0-5.962-2.669-5.962-5.962v-2.003c0-7.554-6.146-14.699-13.699-14.699h-17.206   c-7.554,0-13.699,6.145-13.699,13.699v12.003c0,3.293-2.669,5.962-5.962,5.962c-3.292,0-5.962-2.669-5.962-5.962v-12.003   c0-14.129,11.495-25.623,25.623-25.623h17.206c14.128,0,25.623,12.494,25.623,26.623v2.003   C-287.077-551.251-289.747-548.582-293.039-548.582z" />
                    </g>
                    <path fill="#000000" stroke="#231F20" stroke-width="20" stroke-linecap="round"
                      stroke-linejoin="round" stroke-miterlimit="10"
                      d="  M-669.54-508.264v-20.134c0-18.213,14.765-32.978,32.978-32.978h28.859c18.213,0,32.978,14.765,32.978,32.978v20.134" />
                    <path fill="#000000"
                      d="M52.768,0C33.749,0,18.333,15.417,18.333,34.434c0,5.977,1.524,11.595,4.203,16.494  C22.618,51.3,49.483,97.97,49.483,97.97c0.677,1.171,1.929,1.896,3.284,1.896c1.354-0.002,2.604-0.725,3.283-1.896  c0,0,26.863-46.669,26.946-47.041c2.68-4.898,4.203-10.518,4.203-16.495C87.2,15.417,71.784,0,52.768,0z M52.768,52.776  c-8.55,0-15.48-6.932-15.48-15.48c0-8.549,6.93-15.479,15.48-15.479c8.548,0,15.478,6.93,15.478,15.479  C68.245,45.845,61.315,52.776,52.768,52.776z" />
                  </svg>
                </span>
                <span v-if="this.address == ''"> {{ $t('Set Location') }} </span>
                <p v-else class="text-truncate d-inline-block d-flex flex-column" v-bind:title="address">
                  <span class="outl-nme">{{ adressClarification }}</span>
                  <span class="outl-addrs">{{ address }}</span>
                </p>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
    <!-------New MOdal------->
    <div class="modal fade" id="deliveryModal" tabindex="-1" aria-labelledby="deliveryModallabel" aria-hidden="true"
      v-if="$auth.setting && $auth.setting.is_system_available == '1'">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content location-modal">
          <div class="modal-header">
            <h4 class="modal-title" id="filterLabel">{{ $t('Select Location') }}</h4>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>

            </button>
          </div>
          <div class="modal-body text-center">
            <div classs="hom-mbl-nav" v-if="checkpage != 'review_page'">
              <div class="col-md-12 p-0">
                <div class="position-relative">
                  <div class="search-box d-flex">
                    <div class="position-relative w-100">
                      <vue-google-autocomplete v-if="$auth.google_map_key" id="map" class="form-control" type="text"
                        v-model="addresses" v-on:placechanged="getAddressData"
                        :placeholder="$t('Search for an area or Location name')"
                        :types="'sublocality'"></vue-google-autocomplete>
                      <i class="fas fa-search search-icon"></i>
                    </div>
                    <!-- <a data-dismiss="modal" class="lnk">Cancel</a> -->
                  </div>
                </div>
                <div class="resturant-details pt-3 current-address">
                  <span @click="locatorButtonPressed">
                    <!-- <img src="@/assets/images/gps.svg" />  -->
                    <svg width="25" height="25" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M255.863 168.699C207.816 168.699 168.723 207.793 168.723 255.844C168.723 303.891 207.816 342.984 255.863 342.984C303.914 342.984 343.008 303.891 343.008 255.844C343.008 207.793 303.914 168.699 255.863 168.699Z"
                        fill="#FF8300" />
                      <path
                        d="M497.003 240.843H441.948C434.679 149.84 361.866 77.0233 270.859 69.7577V14.9961C270.859 6.71483 264.144 0 255.863 0C247.582 0 240.867 6.71483 240.867 14.9961V69.7577C149.859 77.0233 77.0467 149.84 69.7772 240.843H14.9961C6.71483 240.843 0 247.558 0 255.843C0 264.124 6.71483 270.839 14.9961 270.839H69.7772C77.0467 361.843 149.859 434.659 240.867 441.929V496.687C240.867 504.968 247.582 511.683 255.863 511.683C264.148 511.683 270.859 504.968 270.859 496.687V441.929C361.866 434.659 434.683 361.843 441.948 270.839H497.003C505.284 270.839 511.999 264.124 511.999 255.843C511.999 247.558 505.284 240.843 497.003 240.843V240.843ZM255.863 412.538C169.461 412.538 99.1678 342.242 99.1678 255.843C99.1678 169.441 169.461 99.1443 255.863 99.1443C342.265 99.1443 412.558 169.441 412.558 255.843C412.558 342.242 342.265 412.538 255.863 412.538Z"
                        fill="#FF8300" />
                    </svg>
                    {{ $t('Use current location') }}
                  </span>
                </div>
                <b-spinner v-if="is_spinning" type="grow" label="Spinning"></b-spinner>
              </div>
            </div>
            <div class="col-md-12 p-0" v-if="$auth.user && $auth.user.token">
              <div class="resturant-details pt-3 saved-addres">
                <h4 class="text-start">{{ $t('Saved Address') }}</h4>
              </div>
              <div class="orders-inner-main plr15 mt-3" v-if="getAddresses.length > 0">
                <div class="orders-lst">
                  <div class="inner-data">
                    <div class="saved-address-main">
                      <div class="d-flex justify-content-between" v-for="(addres, index) in getAddresses" :key="index"
                        @click="selectDefaultAddress(addres)">
                        <div class="saved-address w-100 nav-saved-address">
                          <p class="text-capitalize mb-1">{{ addres.adress_clarification }}</p>
                          <span v-if="addres.flat_no == null || addres.flat_no == ''">
                            {{ addres.house_name + " " + addres.Shiping_address }}</span>
                          <span v-else>{{ addres.flat_no + " " + addres.house_name + ", " + addres.Shiping_address
                            }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="order-offers">
                <div class="text-center my-2" v-if="loading">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <div v-else class="order-offers">
                  <p>{{ $t('No Address Found') }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="$auth.user && $auth.user.token">
            <div class="delivery-address delivery-address-nw border-0">
              <div class="full-btn" @click="addNewAddress">
                <a href="javascript:void(0)">{{ $t('Add New Address') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Stores v-if="isStore" v-on:storeModal="closeStoreModal" />
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapActions, mapGetters, mapMutations } from "vuex";
import config from "../config/index";
import Stores from "./Stores.vue";
export default {
  components: {
    VueGoogleAutocomplete,
    Stores
  },
  props: ['page', "checkpage", "settingData", "singleRestaurant"],
  data() {
    return {
      googleLoaded: false,
      geoaddress: "",
      getAddresses: [],
      loading: true,
      marker: { position: { lat: 23, lng: 72 } },
      center: { lat: 23, lng: 72 },
      mapOptions: {
        disableDefaultUI: true,
      },
      //deliveryModal: true,
      address: "",
      locality: "",
      city: "",
      state: "",
      latitude: "",
      longitude: "",
      addresses: "",
      token: "",
      new_address: "",
      config: config,
      latlng: "",
      userId: "",
      rightClass: '',
      defaultAddress: '',
      show: true,
      onclick: '',
      is_spinning: false,
      adressClarification: '',
      isStore: false,
      open_restaurant: ''
    };
  },
  computed: {
    ...mapGetters("order", ["isPickupDelivery"]),
  },

  beforeCreate() {
    let localCart = localStorage.getItem("cart");
    if (localCart && localCart != '' && localCart != null) {
      this.$store.commit("product/setCart", JSON.parse(localCart));
    }
    let pickupDeliveryFlag = localStorage.getItem("pickupDeliveryFlag");
    if (pickupDeliveryFlag) {
      this.$store.commit("order/pickupdelivery", pickupDeliveryFlag);
    }
  },

  mounted() {
    // if (localStorage.getItem("latitude")) {
    //   this.geolocate();
    // }
    // if (this.$route.name == 'home' || this.$route.name == 'review-cart') {
    //   setTimeout(() => {
    //     if (this.$auth.setting && this.$auth.setting.is_system_available == '1') {
    //       if (!localStorage.getItem("latitude")) {
    //         $('.location-modal').addClass('heightModal');
    //         $('#deliveryModal').modal('show');
    //       } else {
    //         this.address = localStorage.getItem("address");
    //       }
    //     }
    //     if (this.$auth.user) {
    //       this.token = this.$auth.user.token;
    //       this.userId = this.$auth.user.user_id;
    //       if (this.userId) {
    //         this.getPrimaryAddrs();
    //       }
    //       this.AddressesList();
    //     }
    //   }, 1200);
    // }

    setTimeout(() => {
      if (this.$route.name == 'home' || this.$route.name == 'review-cart' || this.$route.name == 'subscription-cart') {
        if (this.$auth.setting && this.$auth.setting.is_system_available == '1') {
          if (!localStorage.getItem("latitude")) {
            $('.location-modal').addClass('heightModal');
            // single vendor
            if (this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0) {
              this.locatorButtonPressed()
              // this.geolocate();
            } else {
              const previewParam = new URLSearchParams(window.location.search);
              const is_preview = previewParam.get('preview');
              if (is_preview == 'true') {
                localStorage.setItem("latitude", this.$auth.setting && this.$auth.setting.system_latitude);
                localStorage.setItem("longitude", this.$auth.setting && this.$auth.setting.system_longitude);
                var lat = parseFloat(this.$auth.setting.system_latitude);
                var lng = parseFloat(this.$auth.setting.system_longitude);
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                  if (status === "OK") {
                    this.address = results[0].formatted_address;
                    this.adressClarification = ''
                    var value = this.address.split(", ");
                    var value1 = this.address.split(" ");
                    var count = value.length;
                    var count1 = value1.length;
                    this.locality = value[count - 4];
                    this.state = value1[count1 - 3];
                    this.city = value[count - 3];
                    this.zip_code = value1[count1 - 2];
                    this.latitude = lat;
                    this.longitude = lng;
                    localStorage.setItem("address", this.address);
                    localStorage.setItem("city", this.city);
                    localStorage.setItem("state", this.state);
                    this.locateAddress(localStorage.getItem("latitude"), localStorage.getItem("longitude"));
                    var getLocatinData = { city: this.city, state: this.state, lat: lat, lng: lng, onclick: "yes" };
                    this.$emit("getLatLong", getLocatinData);
                  }
                });
                this.address = localStorage.getItem("address");
              } else {
                $('#deliveryModal').modal('show');
                $('#deliveryModal').on('hide.bs.modal', this.onModalHide);
              }
            }
          } else {
            this.address = localStorage.getItem("address");
          }
        }
        if (this.$auth.user) {
          this.token = this.$auth.user.token;
          this.userId = this.$auth.user.user_id;
          if (this.userId) {
            // if(this.address == '' || this.address == null){
            //   this.getPrimaryAddrs();
            // }
            this.getPrimaryAddrs();
            // this.getRestaurants()
          } else {
            if (localStorage.getItem("latitude")) {
              this.geolocate();
            }
          }
          this.AddressesList();
        }
      }
    }, 1000);


  },
  methods: {
    ...mapActions("address", ["getAddress", "removeAddress", "setPrimaryAddress", "getPrimaryAddress"]),
    ...mapMutations("order", ["pickupdelivery", "deliveryZone"]),
    ...mapActions("restaurant", ["getRestaurants"]),

    addNewAddress() {
      window.$('#deliveryModal').modal('hide');
      this.$router.push({ name: "add-address" });
    },

    closeStoreModal() {
      this.isStore = false;
    },

    deliveryModalAddress() {
      this.is_spinning = false;
      if (this.$auth && this.$auth.user && this.$auth.user.user_id) {
        this.AddressesList();
        $('.location-modal').addClass('heightModal');
      }
      $('#deliveryModal').modal('show');
    },

    //Current Location button Press
    locatorButtonPressed() {
      this.is_spinning = true;
      const previewParam = new URLSearchParams(window.location.search);
      const is_preview = previewParam.get('preview');
      if (is_preview === 'true') {
        localStorage.setItem("latitude", this.$auth.setting && this.$auth.setting.system_latitude);
        localStorage.setItem("longitude", this.$auth.setting && this.$auth.setting.system_longitude);
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          this.marker.position = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          };
          localStorage.setItem("latitude", position.coords.latitude);
          localStorage.setItem("longitude", position.coords.longitude);
          localStorage.setItem("userCurrentlatitude", position.coords.latitude);
          localStorage.setItem("userCurrentlongitude", position.coords.longitude);
          // localStorage.setItem("systemlatitude", this.$auth.setting.system_latitude);
          // localStorage.setItem("systemlongitude", this.$auth.setting.system_longitude);
          $('#deliveryModal').modal('hide');
          this.locateAddress(position.coords.latitude, position.coords.longitude);
        });
      }
      this.onclick = 'yes';
      localStorage.setItem("geolocation", 'yes');
    },

    // locatorButtonPressed() {
    //   this.is_spinning = true;
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.marker.position = {
    //       lat: parseFloat(position.coords.latitude),
    //       lng: parseFloat(position.coords.longitude),
    //     };
    //     localStorage.setItem("latitude", position.coords.latitude);
    //     localStorage.setItem("longitude", position.coords.longitude);
    //     $('#deliveryModal').modal('hide');
    //     this.locateAddress(parseFloat(position.coords.latitude), parseFloat(position.coords.longitude));

    //   });
    //   this.onclick = 'yes';
    //   localStorage.setItem("geolocation", 'yes');
    // },

    OnAddressList(e, adress_clarification, flat_no, house_name, Shiping_address, latitude, longitude, city, state) {
      this.onclick = 'yes';
      var getLocatinData = {
        city: city,
        state: state,
        lat: latitude,
        lng: longitude,
        onclick: this.onclick
      };
      this.address = flat_no + "," + house_name + "," + Shiping_address;
      this.adressClarification = adress_clarification;
      localStorage.setItem("address", this.address);
      localStorage.setItem("city", city);
      localStorage.setItem("state", state);
      localStorage.setItem("latitude", latitude);
      localStorage.setItem("longitude", longitude);
      this.$emit("getLatLong", getLocatinData);
      $('#deliveryModal').modal('hide');
    },

    AddressesList() {
      this.loading = true;
      this.getAddress({
        devicetoken: "",
        devicetype: "",
        uniqueid: "",
        type: "",
      }).then((data) => {
        if (data.code == 200) {
          this.loading = false;
          this.getAddresses = data.Result;
        } else if (data.code == 101) {
          this.loading = false;
        }
      });
    },

    geolocate() {
      if (!localStorage.getItem("address")) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.marker.position = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          };
          this.locateAddress(parseFloat(position.coords.latitude), parseFloat(position.coords.longitude));
        });
      } else {
        this.address = localStorage.getItem("address");
        this.locateAddress(localStorage.getItem("latitude"), localStorage.getItem("longitude"));
      }
    },

    locateAddress(lat, lng) {
      var getLocatinData = { city: this.city, state: this.state, lat: lat, lng: lng, onclick: this.onclick };
      $('#deliveryModal').modal('hide');
      this.$emit("getLatLong", getLocatinData);
      //var latlng = new google.maps.LatLng(lat, lng);
      //const geocoder = new window.google.maps.Geocoder();
      if (!this.$auth.google_map_key) {
        return false;
      }
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          this.address = results[0].formatted_address;
          this.adressClarification = ''
          var value = this.address.split(", ");
          var value1 = this.address.split(" ");
          var count = value.length;
          var count1 = value1.length;
          this.locality = value[count - 4];
          this.state = value1[count1 - 3];
          this.city = value[count - 3];
          this.zip_code = value1[count1 - 2];
          this.latitude = this.marker.position.lat;
          this.longitude = this.marker.position.lng;
          document.getElementById('map').value = this.address;
          localStorage.setItem("address", this.address);
          localStorage.setItem("latitude", this.latitude);
          localStorage.setItem("longitude", this.longitude);
          localStorage.setItem("city", this.city);
          localStorage.setItem("state", this.state);
        }
      });
      this.address = localStorage.getItem("address");
    },

    getAddressData(addressData, placeResultData) {
      this.onclick = 'yes';
      var latlongs = {
        city: this.city,
        state: this.state,
        lat: parseFloat(addressData.latitude),
        lng: parseFloat(addressData.longitude),
        onclick: this.onclick
      };

      this.addresses = placeResultData.formatted_address;
      this.address = placeResultData.formatted_address;
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
      this.marker.position = { lat: parseFloat(addressData.latitude), lng: parseFloat(addressData.longitude) };
      localStorage.setItem("address", this.addresses);
      localStorage.setItem("latitude", this.latitude);
      localStorage.setItem("longitude", this.longitude);
      localStorage.setItem("city", this.city);
      localStorage.setItem("state", this.state);
      localStorage.setItem("geolocation", 'yes');
      this.$emit("getLatLong", latlongs);
      $('#deliveryModal').modal('hide');
      this.addresses = "";
    },

    //Set Default Location
    selectDefaultAddress(address) {
      this.setPrimaryAddress({
        user_id: this.userId,
        address_id: address.shiping_id,
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
      }).then((data) => {
        if (data.code == 200) {
          if (localStorage.getItem("restaurantId") == null) {
            localStorage.setItem("restaurantId", this.base64_encode(data.Result.restaurant_id));
          } else {
            if (localStorage.getItem("restaurantId") != this.base64_encode(data.Result.restaurant_id)) {
              localStorage.setItem("restaurantId", this.base64_encode(data.Result.restaurant_id));
            }
          }
          this.address = address.flat_no + " " + address.house_name + " " + address.Shiping_address;
          this.adressClarification = address.adress_clarification;
          if (localStorage.getItem("latitude") != address.latitude) {
            localStorage.setItem("address", this.address);
            localStorage.setItem("latitude", address.latitude);
            localStorage.setItem("longitude", address.longitude);
            localStorage.setItem("city", address.city);
            localStorage.setItem("state", address.state);
            this.$store.commit("address/primaryAddressMutation", address);
            var getLocatinData = {
              city: address.city,
              state: address.state,
              lat: address.latitude,
              lng: address.longitude,
              onclick: 'yes'
            };
            this.$emit("getLatLong", getLocatinData);
            $('#deliveryModal').modal('hide');
          }
          this.$emit("sendDefaultAddress", address);
          window.$("#deliveryModal").modal("hide");
        } else {
          this.$emit("sendDefaultAddress", address);
          window.$("#deliveryModal").modal("hide");
        }
      });
    },

    //Get check delivery
    getDelivery(value) {
      localStorage.setItem('orderUploadImages', '');
      this.$emit("isDelivery", value);
      this.$store.commit("order/pickupdelivery", value);
      localStorage.setItem('pickupDeliveryFlag', value);
      if (value == '1') {
        this.rightClass = '';
      } else {
        this.rightClass = 'right';
      }
    },

    //Get address for API call
    getPrimaryAddrs() {
      this.getPrimaryAddress({
      }).then((data) => {
        if (data.code == 200) {
          this.address = data.Result.flat_no + " " + data.Result.house_name + " " + data.Result.Shiping_address;
          this.adressClarification = data.Result.adress_clarification;
          this.selectDefaultAddress(data.Result);
          this.shiping_id = data.Result.shiping_id;
          localStorage.setItem("latitude", data.Result.latitude);
          localStorage.setItem("longitude", data.Result.longitude);
        }
      });
    },

    closeStoreModal() {
      this.isStore = false;
    }


  },
};
</script>
